// Generated by Framer (6f17e57)

import { addFonts, addPropertyControls, ControlType, cx, CycleVariantState, Link, RichText, useVariantState, withCSS } from "framer";
import { LayoutGroup, motion } from "framer-motion";
import * as React from "react";

const enabledGestures = {ZKuPahExt: {hover: true, pressed: true}};

const cycleOrder = ["ZKuPahExt"];

const variantClassNames = {ZKuPahExt: "framer-v-1fz753"};

function addPropertyOverrides(overrides, ...variants) {
const nextOverrides = {}
variants?.forEach(variant => variant && Object.assign(nextOverrides, overrides[variant]))
return nextOverrides
}

const humanReadableVariantMap = {};

const transitions = {default: {damping: 60, delay: 0, duration: 0.3, ease: [0.44, 0, 0.56, 1], mass: 1, stiffness: 500, type: "spring"}};

export interface Props extends React.HTMLAttributes<HTMLDivElement> { layoutId?: string | null; width?: number; height?: number; layoutDependency?: string | number; variant?: keyof typeof humanReadableVariantMap; title?: string; link?: string }

const Component = React.forwardRef<HTMLDivElement, Props>(function({ id, style, className, width, height, layoutId, variant: outerVariant = "ZKuPahExt", title: VTvS6_Bhh = "Button", link: uHRoXhdu7, ...restProps }, ref) {

const outerVariantId = humanReadableVariantMap[outerVariant];

const variant = outerVariantId || outerVariant;

const {baseVariant, classNames, gestureVariant, setGestureState, setVariant, transition, variants} = useVariantState({cycleOrder, defaultVariant: "ZKuPahExt", enabledGestures, transitions, variant, variantClassNames})
const layoutDependency = variants.join("-") + restProps.layoutDependency;

const defaultLayoutId = React.useId()

return (<LayoutGroup id={layoutId ?? defaultLayoutId}>
<motion.div initial={variant} animate={variants} onHoverStart={() => setGestureState({isHovered: true})} onHoverEnd={() => setGestureState({isHovered: false})} onTapStart={() => setGestureState({isPressed: true})} onTap={() => setGestureState({isPressed: false})} onTapCancel={() => setGestureState({isPressed: false})} className={cx("framer-KW0Ku", classNames)} style={{display: "contents"}}>
<Link href={uHRoXhdu7}><motion.a {...restProps} className={`${cx("framer-1fz753", className)} framer-uw3bof`} data-framer-name={"Button"} layoutDependency={layoutDependency} layoutId={"ZKuPahExt"} ref={ref} style={{...style}} transition={transition} {...addPropertyOverrides({"ZKuPahExt-hover": {"data-framer-name": undefined}, "ZKuPahExt-pressed": {"data-framer-name": undefined}}, baseVariant, gestureVariant)}><RichText __fromCanvasComponent children={<React.Fragment><motion.p style={{"--font-selector": "R0Y7UG9wcGlucy02MDA=", "--framer-font-family": "\"Poppins\", sans-serif", "--framer-font-size": "22px", "--framer-font-weight": "600", "--framer-line-height": "28px", "--framer-text-alignment": "center", "--framer-text-color": "var(--extracted-r6o4lv)"}}>Button</motion.p></React.Fragment>} className={"framer-1rqjtli"} data-framer-name={"Join Now for $99/Year"} fonts={["GF;Poppins-600"]} layoutDependency={layoutDependency} layoutId={"Kg3jR6ujy"} style={{"--extracted-r6o4lv": "rgb(5, 104, 57)", "--framer-paragraph-spacing": "0px"}} text={VTvS6_Bhh} transition={transition} variants={{"ZKuPahExt-hover": {"--extracted-r6o4lv": "rgb(4, 78, 43)"}, "ZKuPahExt-pressed": {"--extracted-r6o4lv": "rgb(3, 53, 30)"}}} verticalAlignment={"top"} withExternalLayout/></motion.a></Link>
</motion.div>
</LayoutGroup>)

});

const css = [".framer-KW0Ku [data-border=\"true\"]::after { content: \"\"; border-width: var(--border-top-width, 0) var(--border-right-width, 0) var(--border-bottom-width, 0) var(--border-left-width, 0); border-color: var(--border-color, none); border-style: var(--border-style, none); width: 100%; height: 100%; position: absolute; box-sizing: border-box; left: 0; top: 0; border-radius: inherit; pointer-events: none; }", "@supports (aspect-ratio: 1) { body { --framer-aspect-ratio-supported: auto; } }", ".framer-KW0Ku .framer-uw3bof { display: block; }", ".framer-KW0Ku .framer-1fz753 { align-content: center; align-items: center; display: flex; flex-direction: column; flex-wrap: nowrap; gap: 10px; height: min-content; justify-content: center; padding: 0px 0px 0px 0px; position: relative; text-decoration: none; width: min-content; }", ".framer-KW0Ku .framer-1rqjtli { flex: none; height: auto; position: relative; white-space: pre; width: auto; }", ".framer-KW0Ku .framer-v-1fz753 .framer-1fz753 { cursor: pointer; }", "@supports (background: -webkit-named-image(i)) and (not (font-palette:dark)) { .framer-KW0Ku .framer-1fz753 { gap: 0px; } .framer-KW0Ku .framer-1fz753 > * { margin: 0px; margin-bottom: calc(10px / 2); margin-top: calc(10px / 2); } .framer-KW0Ku .framer-1fz753 > :first-child { margin-top: 0px; } .framer-KW0Ku .framer-1fz753 > :last-child { margin-bottom: 0px; } }"]

/**
 * This is a generated Framer component.
 * @framerIntrinsicHeight 28
 * @framerIntrinsicWidth 74.5
 * @framerCanvasComponentVariantDetails {"propertyName":"variant","data":{"default":{"layout":["auto","auto"]},"MTD6nAh7s":{"layout":["auto","auto"]},"I7S1fd6Es":{"layout":["auto","auto"]}}}
 * @framerVariables {"VTvS6_Bhh":"title","uHRoXhdu7":"link"}
 */
const FramerSFFS6ocxI: React.ComponentType<Props> = withCSS(Component, css, "framer-KW0Ku") as typeof Component;
export default FramerSFFS6ocxI;

FramerSFFS6ocxI.displayName = "Button – Ghost";

FramerSFFS6ocxI.defaultProps = {height: 28, width: 74.5};

addPropertyControls(FramerSFFS6ocxI, {VTvS6_Bhh: {defaultValue: "Button", displayTextArea: false, title: "Title", type: ControlType.String}, uHRoXhdu7: {title: "Link", type: ControlType.Link}} as any)

addFonts(FramerSFFS6ocxI, [{family: "Poppins", moduleAsset: {localModuleIdentifier: "local-module:canvasComponent/SFFS6ocxI:default", url: "https://fonts.gstatic.com/s/poppins/v20/pxiByp8kv8JHgFVrLEj6V1tvFP-KUEg.ttf"}, style: "normal", url: "https://fonts.gstatic.com/s/poppins/v20/pxiByp8kv8JHgFVrLEj6V1tvFP-KUEg.ttf", weight: "600"}])